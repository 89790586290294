// Here you can add other styles

.btn-table {
  padding: 0 0.3rem !important;
}
.block-switch {
  display: block;
}

.display-inline {
  display: inline;
}

.logo-large {
  margin-top: 15px;
}

.settings-table {
  input, select {
    width: 100px;
  }
}